import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, AuthState, ForgotPasswordRequest, UserState } from 'src/app/services/authentication.service';
import { DynamicFormComponent, FormFieldDefinition, TextFormFieldDefinition } from 'src/app/shared/forms';

@Component({

  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent extends DynamicFormComponent implements OnInit {

  public errorMessage?: string;
  public infoMessage?: string;
  public form: FormGroup = new FormGroup({});
  public formDef: FormFieldDefinition<number | string>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
    ) {
      super();
   }

  ngOnInit(): void {
    const astate = this.authenticationService.getAuthData();
    if (astate.userMessage) {
      this.infoMessage = astate.userMessage;
    }

    console.log('auth state ' + JSON.stringify(astate));

    this.formDef = [
      new TextFormFieldDefinition({
        key: 'username', value: astate?.username, readonly: (astate.username !== undefined),
        label: 'User Name', required: true, autocomplete: 'username', placeholder: 'Your username'
      }),
    ];
    this.form = this.toFormGroup(this.formDef);

    astate.userMessage = undefined;
    astate.state = UserState.ForgotPassword;
    this.authenticationService.setAuthState(astate);
  }



  public backToLogin(): void {
    this.router.navigate(['/sec/login']);

  }


  public submit(): void {
    const req = new ForgotPasswordRequest(this.form.controls.username.value);

    this.authenticationService.forgotPassword(req).subscribe(rsp => {
      if (rsp.status === 'success') {
        const astate = new AuthState();
        astate.state = UserState.ForgotPasswordVerification;
        astate.username = this.form.controls.username.value;
        this.authenticationService.setAuthState(astate);
        this.router.navigate(['/sec/forgotpasswordconfirm']);
      }
      else {
        this.errorMessage = rsp.message;
        console.error(rsp.message);
      }
    });
  }

}
