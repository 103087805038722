import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Membership, MeetupService, UserSummary } from '../../services/meetup.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-eventswithcard',
  templateUrl: './eventswithcard.component.html',
  styleUrls: ['./eventswithcard.component.scss']
})
export class EventsWithCardComponent {

  @Input() public groupProfiles?: Membership[];

  public errorMessage?: string;
  public infoMessage?: string;

  public filteredMembers: UserSummary[] = [];
  public member?: UserSummary;
  public imagebase = '';

  constructor(
    private meetupService: MeetupService,
    private router: Router) {

      this.imagebase = `${environment.baseurl}/mh/image?id=`;
  }

  filterMembers(event: any): void {
    this.meetupService.memberSearch(event.query).subscribe(rsp => {
      if ((rsp.status === 'success') && (rsp.data)) {
        this.filteredMembers = rsp.data;
      }
      else {
        this.errorMessage = rsp.message;
      }
    });
  }

  findEvents(): void {
    console.log('find events matching ' + this.member?.meetupId);

    if (!this.member) {
      this.errorMessage = 'Please select a member.';
      return;
    }

    delete this.errorMessage;
    delete this.infoMessage;

    const id = this.member.meetupId;
    this.meetupService.findEventsWith(id).subscribe(rsp => {
      console.log('attendee analysis response ' + rsp);
      if ((rsp.status === 'success') && (rsp.data)) {
        // route to destination.
        this.router.navigate(['/ma/eventswith', id]);
      }
      else {
        this.errorMessage = rsp.message;
      }
    });
  }
}
