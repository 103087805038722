import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './sec/login/login.component';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './sec/signup/signup.component';
import { ConfirmSignupComponent } from './sec/confirmsignup/confirmsignup.component';
import { DynamicFormElementComponent } from './shared/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MatoolsComponent } from './ma/matools.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './shared/authorization-interceptor';
import { MaLinkComponent } from './ma/malink/malink.component';
import { MaConnectedComponent } from './ma/maconnected/maconnected.component';

import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { MessagesModule } from 'primeng/messages';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { AttendeeAnalysisResultsComponent } from './ma/attendeeanalysis/attendeeanalysisresults.component';
import { CrumpReportCardComponent } from './ma/crumpreport/crumpreportcard.component';
import { AttendeeAnalysisCardComponent } from './ma/attendeeanalysis/attendeeanalysiscard.component';
import { EventsWithCardComponent } from './ma/eventswith/eventswithcard.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { EventsWithResultsComponent } from './ma/eventswith/eventswithresults.component';
import { CalendarComponent } from './ma/calendar/calendar.component';
import { CalendarCardComponent } from './ma/calendar/calendarcard.component';
import { MembershipReportCardComponent } from './ma/membershipreport/membershipreportcard.component';
import { MembershipReportResultsComponent } from './ma/membershipreport/membershipreportresults.component';
import { ForgotPasswordComponent } from './sec/forgotpassword/forgotpassword.component';
import { ForgotPasswordConfirmComponent } from './sec/forgotpassword/forgotpasswordconfirm.component';
import { SecureImagePipe } from './shared/secureimage.pipe';
import { ProfileComponent } from './sec/profile/profile.component';


/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ConfirmSignupComponent,
    ForgotPasswordComponent,
    ForgotPasswordConfirmComponent,
    DynamicFormElementComponent,
    MatoolsComponent,
    MaLinkComponent,
    MaConnectedComponent,
    AttendeeAnalysisResultsComponent,
    AttendeeAnalysisCardComponent,
    CalendarComponent,
    CalendarCardComponent,
    CrumpReportCardComponent,
    EventsWithCardComponent,
    EventsWithResultsComponent,
    MembershipReportCardComponent,
    MembershipReportResultsComponent,
    PageNotFoundComponent,
    SecureImagePipe,
    ProfileComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AutoCompleteModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    ToastModule,
    ButtonModule,
    OverlayPanelModule,
    MessagesModule,
    MessageModule,
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey, },
    httpInterceptorProviders,
    MessageService,
    DatePipe,
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
