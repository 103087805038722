import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicFormComponent, FormFieldDefinition, TextFormFieldDefinition } from 'src/app/shared/forms';
import { AuthenticationService, ConfirmSignupRequest, LoginRequest, SignupRequest } from '../../services/authentication.service';

@Component({
  templateUrl: './confirmsignup.component.html',
  styleUrls: ['./confirmsignup.component.scss']
})
export class ConfirmSignupComponent extends DynamicFormComponent  implements OnInit {

  public errorMessage?: string;
  public infoMessage?: string;
  public form: FormGroup = new FormGroup({});
  public formDef: FormFieldDefinition<number | string>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
    ) {
      super();
   }

  ngOnInit(): void {
    const astate = this.authenticationService.getAuthData();
    if (astate.userMessage) {
      this.infoMessage = astate.userMessage;
    }

    console.log('auth state ' + JSON.stringify(astate));

    this.formDef = [
      new TextFormFieldDefinition({
        key: 'username', value: astate?.username, readonly: (astate.username !== undefined),
        label: 'User Name', required: true, autocomplete: 'username', placeholder: 'Your username'
      }),
      new TextFormFieldDefinition({
        key: 'code', pattern: '[0-9]*', maxLength: '6',
        label: 'Code', required: true, placeholder: 'Enter email code'
      })
    ];
    this.formDef.sort((a, b) => a.order - b.order);
    this.form = this.toFormGroup(this.formDef);

    astate.userMessage = undefined;
    this.authenticationService.setAuthState(astate);
  }

  /*
   Form should contain user name field and verification code field.
   username disabled if a value exists.  Otherwise, must retype...
   */

  public submit(): void {
    delete this.errorMessage;

    const creq = new ConfirmSignupRequest() as any;
    Object.keys(this.form.value).forEach(key => {
      creq[key] = this.form.controls[key].value;
    });

    this.authenticationService.confirmSignUp(creq).subscribe(rsp => {
      if (rsp.status === 'success') {
        this.router.navigate(['/sec/login']);
      }
      else {
        this.errorMessage = rsp.message;
        console.error(rsp.message);
      }
    });
  }

  public resendCode(): void {
    let username = this.authenticationService.getAuthData().username;
    if (!username) {
      username = this.form.controls.username.value;
    }
    if (username) {
      this.authenticationService.resendSignUpCode(username).subscribe(rsp => {
        this.infoMessage = rsp.message;
      });
    }
    else {
      console.error('weird that there is no username');
    }
  }
}
