import { Component, OnInit } from '@angular/core';
import { Membership, MeetupService } from '../services/meetup.service';

@Component({
  selector: 'app-matools',
  templateUrl: './matools.component.html',
  styleUrls: ['./matools.component.scss']
})
export class MatoolsComponent implements OnInit {

  public groupProfiles?: Membership[];

  constructor(
    private meetupService: MeetupService
  ) { }

  ngOnInit(): void {
    this.meetupService.myGroups(true).subscribe(rsp => {
      if ((rsp.status === 'success') && (rsp.data)) {
        this.groupProfiles = rsp.data;
      }
      else {
        console.warn(rsp.message);
      }
    });
  }
}
