<div [ngClass]="elementClass" [formGroup]="form">
  <div class="form-group">
    <label [ngClass]="{'required': item.required}" [attr.for]="item.key">{{item.label}}</label>
    <div class="input-group" [ngSwitch]="item.controlType">

      <input *ngSwitchCase="'textbox'" class="form-control" [required]="item.required" [formControlName]="item.key" [id]="item.key" [type]="item.type"
      [attr.maxLength]="item.maxLength ? item.maxLength : null" [readonly]="item.readonly"
      [ngClass]="{ 'error-input': !isValid }" [placeholder]="item.placeholder" [autocomplete]="item.autocomplete" [pattern]="item.pattern">

      <div *ngIf="item.isPassword" class="input-group-append">
        <span class="input-group-text cursor-pointer" (click)="showPass(item.key)">
          <i class="fa fa-eye" [ngClass]="{
              'fa-eye-slash': item.type !== 'password'
            }"></i>
        </span>
      </div>

      <select [id]="item.key" *ngSwitchCase="'dropdown'" [formControlName]="item.key">
        <option *ngFor="let opt of item.options" [value]="opt.key">{{opt.value}}</option>
      </select>
    </div>
    <div *ngIf="form && ngf?.submitted && form.controls[item.key].invalid && (form.controls[item.key].dirty || form.controls[item.key].touched)" style="padding: 0%">
      <span i18n *ngIf="form.controls[item.key].errors?.required" class="col-sm-10 user-error-box no-float">{{item.label}} Required</span>
      <span i18n *ngIf="form.controls[item.key].errors?.email" class="col-sm-10 user-error-box no-float">Valid Address Required</span>
      <span i18n *ngIf="form.controls[item.key].errors?.pattern" class="col-sm-10 user-error-box no-float">Invalid format for input</span>
    </div>

  </div>
</div>

