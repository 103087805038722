import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseCodes } from '../../services/base.service';
import { AuthenticationService, AuthState, LoginRequest, UserState } from '../../services/authentication.service';
import { DynamicFormComponent, FormFieldDefinition, TextFormFieldDefinition } from 'src/app/shared/forms';
import { FormGroup } from '@angular/forms';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends DynamicFormComponent implements OnInit {

  public errorMessage?: string;
  public form: FormGroup = new FormGroup({});
  public formDef: FormFieldDefinition<number | string>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.formDef = [
      new TextFormFieldDefinition({
        key: 'username',
        label: 'User Name', required: true, autocomplete: 'username', placeholder: 'Enter your username'
      }),
      new TextFormFieldDefinition({
        key: 'password', type: 'password',
        label: 'Password', required: true, autocomplete: 'password', placeholder: 'Enter your password'
      })
    ];
    this.form = this.toFormGroup(this.formDef);
  }

  public submit(): void {
    delete this.errorMessage;
    const req = new LoginRequest('', '');
    const r = req as any;
    Object.keys(this.form.value).forEach(key => {
      r[key] = this.form.controls[key].value;
    });
    this.authenticationService.login(req).subscribe(rsp => {
      if (rsp.status === 'success') {
        this.router.config.forEach(r => {
          if (r.data?.useAsDefault) {
            this.router.navigate(['/' + r.path]);
          }
        });
      }
      else {
        this.errorMessage = rsp.message;
        console.error(rsp.message);
        if (rsp.code === ResponseCodes.MustConfirmEmail) {
          setTimeout(() => {
            const astate = AuthState.withState(UserState.SignupPending);
            astate.username = this.form.controls.username.value;
            astate.userMessage = rsp.message;
            this.authenticationService.setAuthState(astate);
            this.router.navigate(['/sec/confirmsignup']);
          }, 5000);
        }
      }
    });
  }

  public showPass(pw: HTMLInputElement): void {
    pw.type = pw.type === 'password' ? 'text' : 'password';
  }


  public forgotPassword(): void {
    this.router.navigate(['/sec/forgotpassword']);
  }

  public signUp(): void {

  }
}
