import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, from, pipe } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

/**
 * This HTTP interceptor inserts the Authorization header into outgoing HTTP requests.
 * The value of the header is the JWT contained in the user's Cognito user session (if it exists).
 * In prior versions if the library, we used the Auth0 library to perform this function which
 * pulled the header value from a local storage value.
 */

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {
  }

  /**
   * Intercept the HTTP Request and insert Authorization header.
   * @param req http request to insert Authorization header into
   * @param next next handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('auth interceptor running for ' + req.method + ' request to ' + req.url);

    // don't bother on an OPTIONS request.
    if (req.method === 'OPTIONS') {
      return next.handle(req);
    }

    /* So what happens whe the token has expired?   Use a refresh token which is then async. */

    const authState = this.authenticationService.getAuthData();
    if (!authState) {
      return next.handle(req);
    }


    return this.authenticationService.getAccessToken().pipe(mergeMap(at => {
      if (at) {
        const req2 = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + at
          }
        });
        return next.handle(req2);
      }
      else {
        return next.handle(req);
      }
    }));
  }
}
