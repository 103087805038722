import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseResponse, BaseResponseWithData, BaseService } from './base.service';
import { environment } from '../../environments/environment';



export class Analysis {
  public id?: string;
}


export class AttendanceAnomaly {
  // absent, noshow, lastminno
  constructor(public type: string, public when: number, public eventId: string, public eventLink: string, public eventName: string, public diff?: number) {

  }
}

export class AttendeeAnalysisItem {
  id?: number;
  name?: string;
  absent?: number;
  noshow?: number;
  attended?: number;
  lastMinuteNos?: number;
  joined?: number;
  thumbnailUrl?: string;
  profileUrl?: string;
  groups?: number;
  rsvps?: number;
  groupRsvps?: number;
  guests?: number;

  mostRecentNoshow?: number;
  mostRecentAbsent?: number;
  mostRecentLastMinuteNo?: number;

  rsvpCreated = 0;
  rsvpUpdated = 0;
  rsvpResponse?: string;

  role?: string;
  title?: string;
  created?: number;
  visited?: number;

  cachedImageUrl?: string;

  anomalies: AttendanceAnomaly[] = [];
  noshows?: AttendanceAnomaly[];
  absences?: AttendanceAnomaly[];
  lateNos?: AttendanceAnomaly[];
}

export class AttendeeAnalysis extends Analysis {
  public groupId?: number;
  public eventId?: string;
  public groupName?: string;
  public eventName?: string;
  public eventDateTime?: number;

  public items?: AttendeeAnalysisItem[];
}


export class UserSummary {
  constructor(public meetupId: string, public name: string, public photo: Partial<Photo>) {
  }
}


export class Membership {
  constructor(public id: string, public meetupId: string, public role: string, public mostRecentVisit: Date, public status: string) {

  }
  public group?: Group;
}

export class Group {
  constructor(
    public id: string, public meetupId: string, public description: string,
    public membershipCount: number, public latitude: number, public longitude: number,
    public foundedDate: Date, public name: string, public urlname: string, public city: string,
    public state: string, public country: string, public zip: string) {

  }

}

export class GroupCategory {
  public id?: string;
  public name?: string;
  public shortname?: string;
  public sort_name?: string;
  public photo?: Photo;
}

export interface Photo {
  id: string;
  meetupId: string;
  photoFile: string;
  baseUrl: string;
  source: string;
  contentType: string;
}

export class EventsQuery {
  constructor(
    public username: string,
    public groupId: number,
    public eventsWith: number, // member id
    public summary: boolean,
    public refresh: boolean = false) {

  }
}

export class BaseMember {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public status: string,
    public joined: number,
    public city: string,
    public country: string,
    public localized_country_name: string,
    public state: string,
    public lat: number,
    public lon: number,
    public photo: Photo,
    public is_pro_admin: boolean) {

  }
}


export class Criteria extends EventsQuery {
  eventsWithMember?: BaseMember;
}

export interface Ticket {
  id: string;
  meetupId: string;
  event: Event;
  user: UserSummary;
  position: number;
  hosting: boolean;
  status: string;
  guestsCount: number;
  updatedAt: Date;
  createdDate: Date;
  lastUpdateDate: Date;

  highlighted: boolean;
}


export class EventsData {
  public criteria?: Criteria;
  public events?: Array<Event>;
}

export interface Event {
  id: string;
  meetupId: string;
  name: string;
  title: string;
  eventUrl: string;
  description: string;
  shortDescription: string;
  howToFindUs: string;
  status: string;
  timeStatus: string;
  dateTime: Date;
  endTime: Date;
  createdAt: Date;
  updated: Date;
  going: number;
  waiting: number;

  group: Group;

  // JG additional fields
  myStatus: string;

}

export class Venue {
  public id?: string;
  public name?: string;
  public lat?: number;
  public lon?: number;
  public repinned?: boolean;
  public address1?: string;
  public city?: string;
  public country?: string;
  public localized_country_name?: string;
  public zip?: string;
  public state?: string;
}




export interface CalendarAnalysis {
  conflects: boolean;
  messages: string[];
}

export interface CalendarResponseData {
  tickets: Array<Ticket>;
  analysis: CalendarAnalysis;
}

export class ReportResponseData {
  public reportId?: string;
  public groupId?: string;
  public groupName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MeetupService extends BaseService {

  private responseCache: Map<string, BaseResponseWithData<any>> = new Map();

  constructor(private http: HttpClient) {
    super();
  }


  public crump(groupId: number, eventId: number): Observable<File> {

    const url = `${environment.baseurl}/mh/crumpreport?eventId=${eventId}&groupId=${groupId}`;
    return this.http.get(url, {
      responseType: 'blob'
    }).pipe(map(blob => {
      return new File([blob], `crumpreport-${eventId}.docx`);
    }));
  }

  public myGroups(summary: boolean): Observable<BaseResponseWithData<Array<Membership>>> {
    const url = `${environment.baseurl}/mh/groups?summary=${(summary ? 'true' : 'false')}`;
    return this.http.get<BaseResponseWithData<Array<Membership>>>(url);
  }

  public myEvents(groupId: number, summary: boolean): Observable<BaseResponseWithData<EventsData>> {
    const url = `${environment.baseurl}/mh/events?groupId=${groupId}&summary=${(summary ? 'true' : 'false')}`;
    return this.http.get<BaseResponseWithData<EventsData>>(url);
  }

  public calendar(): Observable<BaseResponseWithData<CalendarResponseData>> {
    const url = `${environment.baseurl}/mh/calendar`;
    return this.http.get<BaseResponseWithData<CalendarResponseData>>(url);
  }

  public memberSearch(q: string): Observable<BaseResponseWithData<Array<UserSummary>>> {
    const url = `${environment.baseurl}/mh/membersearch?search=${q}&summary=true`;
    return this.http.get<BaseResponseWithData<Array<UserSummary>>>(url);
  }

  public getAttendeeAnalysis(groupId: number, eventId: string): Observable<BaseResponseWithData<AttendeeAnalysis>> {
    const cacheId = `aa-${groupId}-${eventId}`;
    const cached = this.responseCache.get(cacheId);
    if (cached) {
      return of(cached);
    }
    const url = `${environment.baseurl}/mh/attendeeanalysis?eventId=${eventId}&groupId=${groupId}`;
    return this.http.get<BaseResponseWithData<AttendeeAnalysis>>(url).pipe(tap(rsp => {
      if (rsp.status === 'success') {
        rsp.localResponseId = cacheId;
        this.responseCache.set(cacheId, rsp);
      }
      return rsp;
    }));
  }

  public findEventsWith(meetupId: string): Observable<BaseResponseWithData<EventsData>> {
    const cacheId = `ew-${meetupId}`;
    const cached = this.responseCache.get(cacheId);
    if (cached) {
      return of(cached);
    }
    const url = `${environment.baseurl}/mh/events?eventsWith=${meetupId}`;
    return this.http.get<BaseResponseWithData<EventsData>>(url).pipe(tap(rsp => {
      if (rsp.status === 'success') {
        rsp.localResponseId = cacheId;
        this.responseCache.set(cacheId, rsp);
      }
      return rsp;
    }));
  }

  public getCachedRepsonse<T>(id: string): BaseResponseWithData<T> | undefined {
    return this.responseCache.get(id);
  }


  public getReport(groupId: number, reportId: string): Observable<BaseResponseWithData<ReportResponseData>> {
    const cacheId = `aa-${groupId}-${reportId}`;
    const cached = this.responseCache.get(cacheId);
    if (cached) {
      return of(cached);
    }
    const url = `${environment.baseurl}/mh/membershipreport?reportId=${reportId}&groupId=${groupId}`;
    return this.http.get<BaseResponseWithData<ReportResponseData>>(url).pipe(tap(rsp => {
      if (rsp.status === 'success') {
        rsp.localResponseId = cacheId;
        this.responseCache.set(cacheId, rsp);
      }
      return rsp;
    }));
  }

}

