import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event, Membership, MeetupService } from '../../services/meetup.service';

@Component({
  selector: 'app-attendeeanalysiscard',
  templateUrl: './attendeeanalysiscard.component.html',
  styleUrls: ['./attendeeanalysiscard.component.scss']
})
export class AttendeeAnalysisCardComponent {

  public memberships?: Membership[];

  public eventSummaries?: Event[];
  public selectedGroup?: number;
  public selectedEvent?: string;
  public errorMessage?: string;
  public infoMessage?: string;

  constructor(
    private meetupService: MeetupService,
    private router: Router) {

  }

  @Input() set groupProfiles(profiles: Membership[] | undefined) {
    this.memberships = profiles?.filter(m => {
      return m.role === 'EVENT_ORGANIZER' || m.status === 'LEADER';
    });

  }

  groupSelected(): void {
    if (this.selectedGroup) {
      this.meetupService.myEvents(this.selectedGroup, true).subscribe(rsp => {
        if (rsp.status === 'success') {
          this.eventSummaries = rsp.data?.events;
        }
        else {
          console.warn(rsp.message);
        }
      });
    }
  }

  attendeeAnalysis(): void {
    if ((!this.selectedGroup) || (!this.selectedEvent)) {
      this.errorMessage = 'Please select a group and event';
      return;
    }

    delete this.errorMessage;
    delete this.infoMessage;

    this.meetupService.getAttendeeAnalysis(this.selectedGroup, this.selectedEvent).subscribe(rsp => {
      console.log('attendee analysis response ' + rsp);
      if ((rsp.status === 'success') && (rsp.data)) {
        // route to destination.
        this.router.navigate(['/ma/attendeeresults', rsp.data.groupId, rsp.data.eventId]);
      }
      else {
        this.errorMessage = rsp.message;
      }
    });
  }

}
