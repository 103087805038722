import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, AuthState, ForgotPasswordRequest, ResetPasswordRequest, UserState } from 'src/app/services/authentication.service';
import { DynamicFormComponent, FormFieldDefinition, TextFormFieldDefinition } from 'src/app/shared/forms';

@Component({

  templateUrl: './forgotpasswordconfirm.component.html',
  styleUrls: ['./forgotpasswordconfirm.component.scss']
})
export class ForgotPasswordConfirmComponent extends DynamicFormComponent implements OnInit {

  public errorMessage?: string;
  public infoMessage?: string;
  public form: FormGroup = new FormGroup({});
  public formDef: FormFieldDefinition<number | string>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    const astate = this.authenticationService.getAuthData();
    if (astate.userMessage) {
      this.infoMessage = astate.userMessage;
    }

    console.log('auth state ' + JSON.stringify(astate));

    this.formDef = [
      new TextFormFieldDefinition({
        key: 'username', value: astate?.username, readonly: (astate.username !== undefined),
        label: 'User Name', required: true, autocomplete: 'username', placeholder: 'Your username'
      }),
      new TextFormFieldDefinition({
        key: 'code', pattern: '[0-9]*', maxLength: '6',
        label: 'Code', required: true, placeholder: 'Enter email code'
      }),
      new TextFormFieldDefinition({
        key: 'password', type: 'password',
        label: 'Password', required: true, autocomplete: 'new-password', placeholder: 'Enter a password'
      }),
      new TextFormFieldDefinition({
        key: 'confirmpassword', type: 'password',
        label: 'Confirm Password', required: true, autocomplete: 'new-password', placeholder: 'Confirm password'
      })

    ];
    this.form = this.toFormGroup(this.formDef);

    astate.userMessage = undefined;
    astate.state = UserState.ForgotPasswordVerification;
    this.authenticationService.setAuthState(astate);
  }



  public backToLogin(): void {
    this.router.navigate(['/sec/login']);

  }

  public resendCode(): void {
    delete this.infoMessage;
    delete this.errorMessage;

    const req = new ForgotPasswordRequest(this.form.controls.username.value);

    this.authenticationService.forgotPassword(req).subscribe(rsp => {
      if (rsp.status === 'success') {
        this.infoMessage = 'Request sent.  Please check your email for the updated code.';
      }
      else {
        this.errorMessage = rsp.message;
        console.error(rsp.message);
      }
    });
  }

  public submit(): void {
    const pw = this.form.controls.password.value;
    const confirmPw = this.form.controls.confirmpassword.value;
    if (confirmPw !== pw) {
      this.errorMessage = 'Passwords do not match';
      return;
    }

    const req = new ResetPasswordRequest(this.form.controls.username.value,
      this.form.controls.code.value, this.form.controls.password.value);

    this.authenticationService.resetPassword(req).subscribe(rsp => {
      if (rsp.status === 'success') {
        const astate = new AuthState();
        astate.state = UserState.SignedOut;
        this.authenticationService.setAuthState(astate);
        this.router.navigate(['/sec/login']);
      }
      else {
        this.errorMessage = rsp.message;
        console.error(rsp.message);
      }
    });
  }

}
