import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap, map } from 'rxjs/operators';
import { BaseResponse, BaseResponseWithData } from './base.service';
import { JwtHelperService } from '@auth0/angular-jwt';

export class LoginResponseData {
  public accessToken?: string;
  public refreshToken?: string;
}

export class LoginRequest {
  constructor(public username: string, public password: string) {

  }
}

export class RefreshCredentialsRequest {
  constructor(public username?: string, public refreshToken?: string) {

  }
}
export class RefreshCredentialsResponse extends BaseResponse {
  public token?: string;
  public refreshToken?: string;
}

export class SignupResponse extends BaseResponse {
  public id?: string;
}

export class SignupRequest {
  public username?: string;
  public password?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public phone?: string;

  public recaptchaToken?: string;

  constructor() {

  }
}

export class ResetPasswordRequest {
  constructor(public username: string, public code: string, public password: string) {

  }
}

export class ForgotPasswordRequest {
  constructor(public username: string) {

  }
}

export enum UserState {
  SignedOut = 'signedout',
  SignedIn = 'signedin',
  SignupPending = 'signupPending',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordVerification = 'forgotPasswordVerification'
}

export class AuthState {
  public state = UserState.SignedOut;
  public username?: string;
  public token?: string;
  public refreshToken?: string;
  public userMessage?: string;

  static withState(state: UserState): AuthState {
    const rv = new AuthState();
    rv.state = state;
    return rv;
  }
}

export class ConfirmSignupRequest {
  public username?: string;
  public code?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public authState: AuthState = new AuthState();
  public master = new ReplaySubject<AuthState>(1);
  private storage = window.localStorage;
  private privateHttp: HttpClient;

  constructor(private http: HttpClient, private httpbackend: HttpBackend) {
    this.privateHttp = new HttpClient(httpbackend);

    const stored = this.storage.getItem('authstate');
    if (stored) {
      console.log('reconsitute auth state from ' + stored);
      this.authState = JSON.parse(stored);
    }
    this.master.next(this.authState);
  }

  public getAuthData(): AuthState {
    return this.authState;
  }

  public setAuthState(astate: AuthState): void {
    const str = JSON.stringify(astate);
    console.log(`set auth state to ${str}`);
    this.storage.setItem('authstate', str);
    this.authState = astate;
    this.master.next(astate);
  }

  public getAccessToken(): Observable<string|undefined> {
    if (!this.authState || !this.authState.token) {
      return of(undefined);
    }
    const helper = new JwtHelperService();
    if ((helper.isTokenExpired(this.authState.token)) && this.authState.refreshToken) {
      return this.refreshCredentials();
    }
    else {
      return of(this.authState.token);
    }
  }

  public refreshCredentials(): Observable<string|undefined> {
    if (!this.authState || !this.authState.token) {
      console.warn('unable to refresh due to auth state ' + JSON.stringify(this.authState));
      return of(undefined);
    }
    if (this.authState.refreshToken) {
      const u = `${environment.baseurl}/sec/refreshcredentials`;
      const req = new RefreshCredentialsRequest(this.authState.username, this.authState.refreshToken);
      return this.privateHttp.post<BaseResponseWithData<LoginResponseData>>(u, req).pipe(map(r => {
        if (r.status === 'success') {
          this.authState.token = r.data?.accessToken;
          this.authState.refreshToken = r.data?.refreshToken;
          return r.data?.accessToken;
        }
        else {
          console.error('failed to refresh access token ' + r.message);
          return undefined;
        }
      }));
    }
    else {
      console.warn('cannot refresh credentials since we have no refresh token');
      return of(undefined);
    }
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<BaseResponse> {
    const u = `${environment.baseurl}/sec/forgotpassword`;
    return this.http.post<BaseResponse>(u, req);
  }

  public resetPassword(req: ResetPasswordRequest): Observable<BaseResponse> {
    const u = `${environment.baseurl}/sec/resetpassword`;
    return this.http.post<BaseResponse>(u, req);
  }

  public signUp(req: SignupRequest): Observable<SignupResponse> {
    const u = `${environment.baseurl}/sec/signup`;
    return this.http.post<SignupResponse>(u, req);
  }

  public confirmSignUp(req: ConfirmSignupRequest): Observable<BaseResponse> {
    const u = `${environment.baseurl}/sec/confirmsignup`;
    return this.http.post<BaseResponse>(u, req);
  }

  public resendSignUpCode(username: string): Observable<BaseResponse> {
    const u = `${environment.baseurl}/sec/resendsignupcode`;
    return this.http.post<BaseResponse>(u, { username });
  }

  public login(loginRequest: LoginRequest): Observable<BaseResponseWithData<LoginResponseData>> {
    const u = `${environment.baseurl}/sec/login`;
    return this.http.post<BaseResponseWithData<LoginResponseData>>(u, loginRequest).pipe(tap((rsp => {
      if (rsp.status === 'success') {
        const st = AuthState.withState(UserState.SignedIn);
        st.token = rsp.data?.accessToken;
        st.refreshToken = rsp.data?.refreshToken;
        st.username = loginRequest.username;
        this.setAuthState(st);
      }
    })));
  }

  public localLogout(): void {
    this.setAuthState(AuthState.withState(UserState.SignedOut));
  }

  public logout(): Observable<BaseResponse> {
    const u = `${environment.baseurl}/sec/logout`;
    return this.http.post<BaseResponse>(u, {}).pipe(tap((rsp => {
      if (rsp.status === 'success') {
        this.setAuthState(AuthState.withState(UserState.SignedOut));
      }
    })));
  }
}
