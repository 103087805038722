<div class="container" id="card-container">
  <div class="row mt-2">
    <div class="col">
      <div class="card-deck">

        <app-crumpcard class="mt-3 card channel-card" [groupProfiles]="groupProfiles"></app-crumpcard>

        <app-calendarcard class="mt-3 card channel-card"></app-calendarcard>

        <app-attendeeanalysiscard class="mt-3 card channel-card" [groupProfiles]="groupProfiles"></app-attendeeanalysiscard>

        <app-eventswithcard class="mt-3 card channel-card" [groupProfiles]="groupProfiles"></app-eventswithcard>

        <app-membershipreportcard class="mt-3 card channel-card" [groupProfiles]="groupProfiles"></app-membershipreportcard>

      </div>
    </div>
  </div>
</div>