<div *ngIf="errorMessage" class="mt-3 alert alert-danger" role="alert">
  {{errorMessage}}
</div>

<h3 *ngIf="data">Events with {{data?.criteria?.eventsWithMember?.name}}</h3>

<div class="row">
  <table class="table" *ngIf="events && events.length > 0">
    <thead>
      <tr>
        <th>Date/Time</th>
        <th>Title</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of events">
        <td>{{event.dateTime | date}}</td>
        <td><a href="{{event.eventUrl}}">{{event.title}}</a></td>
        <td><div [innerHTML]="event.description"></div></td>
      </tr>
    </tbody>
  </table>
</div>