  <div class="card-body">
    <h4 class="card-title">Find Events With</h4>
    <p class="card-text">Wonder what events you attended with someone?</p>
    <p>
    <form>
      <div class="form-group">
        <label for="member">Member Name</label>

        <p-autoComplete class="d-block" field="name" name="member" id="member" [(ngModel)]="member" [suggestions]="filteredMembers"
          (completeMethod)="filterMembers($event)">
          <ng-template let-member pTemplate="item">
            <img *ngIf="member.memberPhoto && member.memberPhoto.meetupId !== '0'" src="{{imagebase}}{{member.memberPhoto.meetupId}}" style="width:28px;margin:0px 0 0px 0px" />
            <small style="float:right">{{member.name}}</small>
          </ng-template>
        </p-autoComplete>
      </div>
    </form>

     <div *ngIf="errorMessage" class="mt-3 alert alert-danger" role="alert">
      {{errorMessage}}
    </div>
    <div *ngIf="infoMessage" class="mt-3 alert alert-info" role="alert">
      {{infoMessage}}
    </div>
  </div>
  <div class="card-footer">
    <button (click)="findEvents()" class="btn btn-primary">Find Events</button>
  </div>