
<div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
  <p *ngIf="status === 'success'" class="lead">
    Congratulations, {{username}}.   You have connected to meetup.  We will automatically redirect you to the meetup assistant tools.
  </p>
  <p *ngIf="status !== 'success'" class="lead">
    Unfortunately {{username}}, we were unable to connect with Meetup.com due to {{message}}.
  </p>
</div>

