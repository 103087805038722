import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserState } from './services/authentication.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../environments/environment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public signedIn = false;
  public ws?: WebSocketSubject<object>;
  private sub?: Subscription;
  public hasAccessToken = false;

  public profileImageSource = environment.baseurl + '/profile/image';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.ws = webSocket<object>(environment.baseurl.replace('https', 'wss').replace('http', 'ws'));

    this.authService.master.subscribe({
      next: (authState) => {
        console.log('auth state now ' + JSON.stringify(authState));
        this.signedIn = (authState.state === UserState.SignedIn);
        if (authState.state === UserState.SignedIn) {
          this.sub = this.subscribe(authState.token);

          const helper = new JwtHelperService();
          const decoded = helper.decodeToken(authState.token);
          // console.log(decoded);
          this.hasAccessToken = decoded.hasAccessToken;
        }
        else if (authState.state === UserState.SignedOut) {
          if (this.sub) {
            this.sub.unsubscribe();
            delete this.sub;
          }
          this.hasAccessToken = false;
        }
      },
      error: (err => {
        console.error(err);
      }),
      complete: () => {
        console.log('how did auth state master completed');
      }
    });
  }

  private tryReconnect(first: boolean): void {
    if (first) {
      delete this.sub;
    }
    setTimeout(() => {
      if (this.signedIn) {
        this.sub = this.subscribe(this.authService.getAuthData().token);
      }
    }, 10000);
  }

  private subscribe(token?: string): Subscription | undefined {
    if (!this.ws) {
      return undefined;
    }
    const sub = this.ws.subscribe({
      next: message => {
        console.log('ws message received ' + JSON.stringify(message));
        try {
          const m = message as any;
          if (!m.messageType) {
            console.error('unexpected message ' + message);
            return;
          }
          else if ('alert' === m.messageType) {
            this.messageService.add({ severity: m.severity, summary: m.summary, detail: m.message, sticky: m.sticky, 
              id: m.id, data: m.data });
          }
        }
        catch (error) {
          console.error(error);
        }
      },
      error: e => {
        console.warn(e);
        if (e.type === 'close') {
          console.log('web socket was closed.  Was it us?');
          this.tryReconnect(true);
        }
      },
      complete: () => {
        console.log('ws complete');
      }
    });
    const authmsg = {
      messageType: 'authentication',
      token
    };
    this.ws.next(authmsg);
    return sub;
  }

  public logout(): void {
    console.log('do logout');
    this.authService.logout().subscribe({
      next: (rsp) => {
        if (rsp.status === 'success') {
          this.router.navigate(['/sec/login']);
        }
      },
      error: (err) => {
        this.authService.localLogout();
        this.router.navigate(['/sec/login']);
      }
    });
  }

  onReject(arg: any): void {
    console.log('on reject ' + JSON.stringify(arg));
    this.messageService.clear();
  }

  onConfirm(arg: any): void {
    console.log('on confirm ' + JSON.stringify(arg));
    if (arg.type === 'attendeeanalysisresults') {
      console.log('route to attendee analysis for ' + arg.id);
      this.router.navigate(['/ma/attendeeresults', arg.id]);
    }
    this.messageService.clear();
  }
}
