<div *ngIf="errorMessage" class="mt-3 alert alert-danger" role="alert">
  {{errorMessage}}
</div>

<h3 *ngIf="data">Attendee Analysis for {{data?.groupName}} - {{data?.eventName}}</h3>

<div class="row">
  <div class="col-6">
    <h5>Going</h5>
    <table class="table" *ngIf="going && going.length > 0">
      <thead>
        <tr>
          <th>Attendee</th>
          <th>No Shows</th>
          <th>Absent</th>
          <th>Last Minute No</th>
          <th>Total RSVPs</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of going">
          <td class="row">
            <img style="max-width: 50px; max-height: 50px;" src="{{i.cachedImageUrl}}">
            <div style="line-height: 0.75" class="col">
              <small><a href='{{i.profileUrl}}'>{{i.name}}</a></small>
              <small *ngIf="i.guests"> +{{i.guests}}</small><br>
              <small *ngIf="i.title">{{i.title}}</small><br *ngIf="i.title">
              <small style="font-size:xx-small">RSVP: {{i.rsvpUpdated | date}}</small><br>
              <small style="font-size:xx-small">Joined: {{i.created | date}}</small><br>
              <small style="font-size:xx-small">Visited: {{i.visited | date}}</small>
            </div>
          </td>
          <td>
            <div *ngIf="i.noshow">
              <p-overlayPanel appendTo='body' #op>
                <ng-template pTemplate>
                  <table class='table'>
                    <thead><tr><th>When</th><th>What</th></tr></thead>
                    <tr *ngFor="let anom of i.noshows">
                      <td>{{anom.when | date}}</td>
                      <td><a target="blank" href="{{anom.eventLink}}">{{anom.eventName}}</a></td>
                    </tr>
                  </table>
                </ng-template>
              </p-overlayPanel>
              <a role='button' class='btn' (click)="op.toggle($event)">{{i.noshow}}</a>
            </div>
            <div *ngIf="!i.noshow">
              <a *ngIf="!i.noshow" role='button' class='btn'>{{i.noshow}}</a>
            </div>
          </td>
          <td>
            <div *ngIf="i.absent">
              <p-overlayPanel appendTo='body' #op>
                <ng-template pTemplate>
                  <table class='table'>
                    <thead><tr><th>When</th><th>What</th></tr></thead>
                    <tr *ngFor="let anom of i.absences">
                      <td>{{anom.when | date}}</td>
                      <td><a target="blank" href="{{anom.eventLink}}">{{anom.eventName}}</a></td>
                    </tr>
                  </table>
                </ng-template>
              </p-overlayPanel>
              <a role='button' class='btn' (click)="op.toggle($event)">{{i.absent}}</a>
            </div>
            <div *ngIf="!i.absent">
              <a *ngIf="!i.absent" role='button' class='btn'>{{i.absent}}</a>
            </div>
          </td>

          <td>
            <div *ngIf="i.lastMinuteNos">
              <p-overlayPanel appendTo='body' #op>
                <ng-template pTemplate>
                  <div style="max-height: 600px; overflow-y: scroll;">
                    <table class="table">
                    <thead><tr><th>When</th><th>What</th><th>Hours Before</th></tr></thead>
                    <tr *ngFor="let anom of i.lateNos">
                      <td>{{anom.when | date}}</td>
                      <td><a target="blank" href="{{anom.eventLink}}">{{anom.eventName}}</a></td>
                      <td>{{anom.diff}}</td>
                    </tr>
                  </table>
                </div>
                </ng-template>
              </p-overlayPanel>
              <a role='button' class='btn' (click)="op.toggle($event)">{{i.lastMinuteNos}}</a>
            </div>
            <div *ngIf="!i.lastMinuteNos">
              <a *ngIf="!i.lastMinuteNos" role='button' class='btn'>{{i.lastMinuteNos}}</a>
            </div>
          </td>
          <td>{{i.groupRsvps}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-6">
    <h5>Wait List</h5>
    <table class="table" *ngIf="waitlist && waitlist.length > 0">
      <thead>
        <tr>
          <th>Attendee</th>
          <th>No Shows</th>
          <th>Absent</th>
          <th>Last Minute No</th>
          <th>Total RSVPs</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of waitlist">
          <td class="row">
            <img style="max-width: 50px; max-height: 50px;" src="{{i.cachedImageUrl}}">
            <div style="line-height: 0.75" class="col">
              <small><a target="_blank" href='{{i.profileUrl}}'>{{i.name}}</a></small>
              <small *ngIf="i.guests"> +{{i.guests}}</small><br>
              <small *ngIf="i.title">{{i.title}}</small><br *ngIf="i.title">
              <small style="font-size:xx-small">RSVP: {{i.rsvpUpdated | date}}</small><br>
              <small style="font-size:xx-small">Joined: {{i.created | date}}</small><br>
              <small style="font-size:xx-small">Visited: {{i.visited | date}}</small>
            </div>
          </td>
          <td>
            <div *ngIf="i.noshow">
              <p-overlayPanel appendTo='body' #op>
                <ng-template pTemplate>
                  <table class='table'>
                    <thead><tr><th>When</th><th>What</th></tr></thead>
                    <tr *ngFor="let anom of i.noshows">
                      <td>{{anom.when | date}}</td>
                      <td><a target="_blank" href="{{anom.eventLink}}">{{anom.eventName}}</a></td>
                    </tr>
                  </table>
                </ng-template>
              </p-overlayPanel>
              <a role='button' class='btn' (click)="op.toggle($event)">{{i.noshow}}</a>
            </div>
            <div *ngIf="!i.noshow">
              <a *ngIf="!i.noshow" role='button' class='btn'>{{i.noshow}}</a>
            </div>
          </td>
          <td>
            <div *ngIf="i.absent">
              <p-overlayPanel appendTo='body' #op>
                <ng-template pTemplate>
                  <table class='table'>
                    <thead><tr><th>When</th><th>What</th></tr></thead>
                    <tr *ngFor="let anom of i.absences">
                      <td>{{anom.when | date}}</td>
                      <td><a target="blank" href="{{anom.eventLink}}">{{anom.eventName}}</a></td>
                    </tr>
                  </table>
                </ng-template>
              </p-overlayPanel>
              <a role='button' class='btn' (click)="op.toggle($event)">{{i.absent}}</a>
            </div>
            <div *ngIf="!i.absent">
              <a *ngIf="!i.absent" role='button' class='btn'>{{i.absent}}</a>
            </div>
          </td>

          <td>
            <div *ngIf="i.lastMinuteNos">
              <p-overlayPanel appendTo='body' #op>
                <ng-template pTemplate>
                  <div style="max-height: 600px; overflow-y: scroll;">
                    <table class="table">
                    <thead><tr><th>When</th><th>What</th><th>Hours Before</th></tr></thead>
                    <tr *ngFor="let anom of i.lateNos">
                      <td>{{anom.when | date}}</td>
                      <td><a target="blank" href="{{anom.eventLink}}">{{anom.eventName}}</a></td>
                      <td>{{anom.diff}}</td>
                    </tr>
                  </table>
                </div>
                </ng-template>
              </p-overlayPanel>
              <a role='button' class='btn' (click)="op.toggle($event)">{{i.lastMinuteNos}}</a>
            </div>
            <div *ngIf="!i.lastMinuteNos">
              <a *ngIf="!i.lastMinuteNos" role='button' class='btn'>{{i.lastMinuteNos}}</a>
            </div>
          </td>
          <td>{{i.groupRsvps}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>