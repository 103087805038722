<div class="container py-5">
  <div class="row">
    <div class="col-md-6 mx-auto border-left border-right border-bottom p-0">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">Meetup Assistant Login</h5>

          <form #loginForm="ngForm" (ngSubmit)="submit()" class="m-3" [formGroup]="form">
            <div class="row">
              <ng-template ngFor let-def [ngForOf]="formDef">
                <div class="col-12">
                  <app-form-element class="w-100" [item]="def" [form]="form" [ngf]="loginForm"></app-form-element>
                </div>
                <div *ngIf="def.breakLine" class="col"></div>
              </ng-template>
            </div>

            <div class="form-group justify-content-center mt-4 pl-5 pr-5">
              <button type="submit" class="btn btn-primary px-4 float-right">Log In</button>
              <a [routerLink]="" (click)="forgotPassword()" class="pt-2 link color-blue d-block" i18n
                id="forgotPWLink">Forgot Password?</a>
            </div>
            <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
              {{errorMessage}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>