import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  templateUrl: './maconnected.component.html',
  styleUrls: ['./maconnected.component.scss']
})
export class MaConnectedComponent {

  public username?: string;
  public status?: string;
  public message?: string;

  constructor(
    private configService: ConfigService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.username = authService.authState.username;
    this.status = route.snapshot.paramMap.get('status') || 'success';
    this.message = route.snapshot.paramMap.get('message') || undefined;

    if (this.status === 'success') {
      authService.refreshCredentials().subscribe(cred => {
        console.debug('refresh cred returned ' + cred);
        if (cred) {
          setTimeout(() => {
            this.router.navigate(['/ma/matools']);
          }, 10 * 1000);
        }
        else {
          this.status = 'failed';
          this.message = 'credential refresh failure.';
        }
      });
    }
  }
}
