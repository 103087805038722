import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttendeeAnalysisResultsComponent } from './ma/attendeeanalysis/attendeeanalysisresults.component';
import { CalendarComponent } from './ma/calendar/calendar.component';
import { MaConnectedComponent } from './ma/maconnected/maconnected.component';
import { MaLinkComponent } from './ma/malink/malink.component';
import { MatoolsComponent } from './ma/matools.component';
import { ConfirmSignupComponent } from './sec/confirmsignup/confirmsignup.component';
import { LoginComponent } from './sec/login/login.component';
import { SignupComponent } from './sec/signup/signup.component';
import { HasAccessTokenGuard } from './shared/hasaccesstokenguard';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { EventsWithResultsComponent } from './ma/eventswith/eventswithresults.component';
import { MembershipReportResultsComponent } from './ma/membershipreport/membershipreportresults.component';
import { ForgotPasswordComponent } from './sec/forgotpassword/forgotpassword.component';
import { ForgotPasswordConfirmComponent } from './sec/forgotpassword/forgotpasswordconfirm.component';
import { ProfileComponent } from './sec/profile/profile.component';

const routes: Routes = [
  {
    path: 'sec/login',
    component: LoginComponent
  },
  {
    path: 'sec/signup',
    component: SignupComponent
  },
  {
    path: 'sec/confirmsignup',
    component: ConfirmSignupComponent
  },
  {
    path: 'sec/forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'sec/forgotpasswordconfirm',
    component: ForgotPasswordConfirmComponent
  },
  {
    path: 'sec/profile',
    component: ProfileComponent
  },
  {
    path: 'ma/matools',
    component: MatoolsComponent,
    canActivate: [HasAccessTokenGuard],
    data: {
      useAsDefault: true
    }
  },
  {
    path: 'ma/calendar',
    component: CalendarComponent,
    canActivate: [HasAccessTokenGuard],
  },
  {
    path: 'ma/malink',
    component: MaLinkComponent
  },
  {
    path: 'ma/maconnected',
    component: MaConnectedComponent
  },
  {
    path: 'ma/attendeeresults/:gid/:eid',
    component: AttendeeAnalysisResultsComponent
  },
  {
    path: 'ma/reportresults/:gid/:rid',
    component: MembershipReportResultsComponent
  },
  {
    path: 'ma/eventswith/:id',
    component: EventsWithResultsComponent
  },
  {
    path: '',
    redirectTo: '/ma/matools',
    pathMatch: 'full'
  }, // redirect to `first-component`
  {
    path: '**',
    component: PageNotFoundComponent
  },  // Wildcard route for a 404 page


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
