import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MeetupService } from '../../services/meetup.service';

@Component({
  selector: 'app-calendarcard',
  templateUrl: './calendarcard.component.html',
  styleUrls: ['./calendarcard.component.scss']
})
export class CalendarCardComponent {

  public errorMessage?: string;
  public infoMessage?: string;

  constructor(
    private meetupService: MeetupService,
    private router: Router) {

  }

  viewcalendar(): void {
    this.router.navigate(['/ma/calendar']);
  }

}
