import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';
import { AttendeeAnalysis, AttendeeAnalysisItem, MeetupService, ReportResponseData } from 'src/app/services/meetup.service';

import { environment } from '../../../environments/environment';



@Component({
  templateUrl: './membershipreportresults.component.html',
  styleUrls: ['./membershipreportresults.component.scss']
})
export class MembershipReportResultsComponent implements OnInit {

  public errorMessage?: string;
  public data?: ReportResponseData;

  constructor(
    private configService: ConfigService,
    private authService: AuthenticationService,
    private meetupService: MeetupService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const groupId = this.route.snapshot.params.gid;
    const reportId = this.route.snapshot.params.rid;
    console.log('id in snapshot is ' + groupId + ' ' + reportId);
    this.meetupService.getReport(groupId, reportId).subscribe(rsp => {
      this.data = rsp.data;
    });
  }

  content(item: any): string {
    return 'Hello';
  }
}
