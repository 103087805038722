import { Component, Input } from '@angular/core';
import { Event, Membership, MeetupService } from '../../services/meetup.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crumpcard',
  templateUrl: './crumpreportcard.component.html',
  styleUrls: ['./crumpreportcard.component.scss']
})
export class CrumpReportCardComponent {

  @Input() public groupProfiles?: Membership[];

  public eventSummaries?: Event[];
  public selectedGroup?: number;
  public selectedEvent?: number;
  public crumpErrorMessage?: string;

  constructor(private meetupService: MeetupService) {

  }

  groupSelected(): void {
    if (this.selectedGroup) {
      this.meetupService.myEvents(this.selectedGroup, true).subscribe(rsp => {
        if (rsp.status === 'success') {
          this.eventSummaries = rsp.data?.events;
        }
        else {
          console.warn(rsp.message);
        }
      });
    }
  }

  crump(): void {
    if ((!this.selectedGroup) || (!this.selectedEvent)) {
      this.crumpErrorMessage = 'Please select a group and event';
      return;
    }
    this.meetupService.crump(this.selectedGroup, this.selectedEvent).subscribe(file => {
      saveAs(file);
    });
  }

}
