export class BaseResponse {
  public status?: string;
  public code?: string;
  public message?: string;
}

export class BaseResponseWithData<T> {
  public localResponseId?: string;
  public status?: string;
  public code?: string;
  public message?: string;
  public data?: T;
}


export class BaseService {

  public makeId(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public makeNumericId(length: number): string {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


}

export enum ResponseCodes {
  MustConfirmEmail = '5001'
}
