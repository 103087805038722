import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicFormComponent, FormFieldDefinition, TextFormFieldDefinition } from 'src/app/shared/forms';
import { AuthenticationService, AuthState, SignupRequest, UserState } from '../../services/authentication.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Config, ConfigService } from 'src/app/services/config.service';

@Component({
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends DynamicFormComponent implements OnInit {

  public errorMessage?: string;
  public form: FormGroup = new FormGroup({});
  public formDef: FormFieldDefinition<number | string>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private configService: ConfigService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
  }

  ngOnInit(): void {
    this.formDef = [
      new TextFormFieldDefinition({
        key: 'firstName',
        label: 'First Name', required: true, autocomplete: 'given-name', placeholder: 'Enter a first name'
      }),
      new TextFormFieldDefinition({
        key: 'lastName',
        label: 'Last Name', required: true, autocomplete: 'family-name', placeholder: 'Enter a last name'
      }),
      new TextFormFieldDefinition({
        key: 'username',
        label: 'User Name', required: true, autocomplete: 'username', placeholder: 'Enter a username'
      }),
      new TextFormFieldDefinition({
        key: 'email', type: 'email',
        label: 'Email', required: true, autocomplete: 'email', placeholder: 'Enter an email address'
      }),
      new TextFormFieldDefinition({
        key: 'phone', breakLine: true, type: 'tel', pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
        label: 'Phone', required: false, autocomplete: 'phone', placeholder: 'Enter a phone number'
      }),
      new TextFormFieldDefinition({
        key: 'password', type: 'password',
        label: 'Password', required: true, autocomplete: 'new-password', placeholder: 'Enter a password'
      }),
      new TextFormFieldDefinition({
        key: 'confirmpassword', type: 'password',
        label: 'Confirm Password', required: true, autocomplete: 'new-password', placeholder: 'Confirm password'
      })
    ];
    this.formDef.sort((a, b) => a.order - b.order);
    this.form = this.toFormGroup(this.formDef);

  }

  private doSignUp(token?: string): void {
    const signupRequest = new SignupRequest();
    const r = signupRequest as any;
    Object.keys(this.form.value).forEach(key => {
      r[key] = this.form.controls[key].value;
    });
    signupRequest.recaptchaToken = token;

    this.authenticationService.signUp(signupRequest).subscribe(rsp => {
      if (rsp.status === 'success') {
        const astate = new AuthState();
        astate.state = UserState.SignupPending;
        astate.username = signupRequest.username;
        astate.userMessage = rsp.message;

        this.authenticationService.setAuthState(astate);

        this.router.navigate(['/sec/confirmsignup']);
      }
      else {
        this.errorMessage = rsp.message;
        console.error(rsp.message);
      }
    });
  }

  public submit(): void {

    delete this.errorMessage;

    const pw = this.form.controls.password.value;
    const confirmPw = this.form.controls.confirmpassword.value;
    if (confirmPw !== pw) {
      this.errorMessage = 'Passwords do not match';
      return;
    }

    this.configService.getConfig().then(cfg => {
      console.log(JSON.stringify(cfg));
      if (cfg.recaptchaRequired) {
        this.recaptchaV3Service.execute('signup').subscribe({
          next: (token: string) => {
            this.doSignUp(token);
          },
          error: (err) => {
            console.error('recaptcha service returned error');
            console.error(err);
          }
        });
      }
      else {
        this.doSignUp();
      }
    });
  }

  public resendCode(): void {

  }
}
