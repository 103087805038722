
<div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
  <h1 class="display-4">Link to Meetup</h1>
  <p class="lead">
    To use the Meetup Assistant, you must first connect your account with meetup.
    Use the link below to authorize Meetup Assistant to help you more effectively use the meetup.com site.
  </p>

  <p class="lead">
    <a href="https://secure.meetup.com/oauth2/authorize?client_id={{clientId}}&response_type=code&redirect_uri={{redirUrl}}&state={{username}}">
    Click here to link to meetup.com
    </a>
  </p>
</div>

