<div class="card-body">
  <h4 class="card-title">Attendee Analysis</h4>
  <p class="card-text">Are you leading an event? Let's take a look into who is going.</p>
  <p>
  <form>
    <div class="form-group">
      <label for="group">Meetup Group</label>
      <select name="group" [(ngModel)]="selectedGroup" (change)="groupSelected()" class="form-control" id="group">
        <option *ngFor="let m of memberships" [value]="m.group?.meetupId">{{m.group?.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="event">Event</label>
      <select name="event" [(ngModel)]="selectedEvent" class="form-control" id="event">
        <option *ngFor="let e of eventSummaries" [value]="e.meetupId">{{e.name}}</option>
      </select>
    </div>
  </form>
  <div *ngIf="errorMessage" class="mt-3 alert alert-danger" role="alert">
    {{errorMessage}}
  </div>
  <div *ngIf="infoMessage" class="mt-3 alert alert-info" role="alert">
    {{infoMessage}}
  </div>
</div>
<div class="card-footer">
  <button (click)="attendeeAnalysis()" class="btn btn-primary">Analyze</button>
</div>