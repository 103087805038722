import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event, Membership, MeetupService } from '../../services/meetup.service';

export class ReportType {
  public id?: string;
  public name?: string;
}

@Component({
  selector: 'app-membershipreportcard',
  templateUrl: './membershipreportcard.component.html',
  styleUrls: ['./membershipreportcard.component.scss']
})
export class MembershipReportCardComponent {

  public groupProfileValues?: Membership[];

  public reportTypes?: Array<ReportType>;
  public selectedGroup?: number;
  public selectedReport?: string;
  public errorMessage?: string;
  public infoMessage?: string;

  constructor(
    private meetupService: MeetupService,
    private router: Router) {

      this.reportTypes = [
        {id: 'infrequent-visitors', name: 'Infrequent Visitors'}
      ];

  }

  @Input() set groupProfiles(profiles: Membership[] | undefined) {
    this.groupProfileValues = profiles?.filter(p => {
      return ['assistant_organizer', 'coorganizer', 'event_organizer', 'organizer'].includes(p.role || '');
    });

  }

   generateReport(): void {
    if ((!this.selectedGroup) || (!this.selectedReport)) {
      this.errorMessage = 'Please select a group and report';
      return;
    }

    delete this.errorMessage;
    delete this.infoMessage;

    this.meetupService.getReport(this.selectedGroup, this.selectedReport).subscribe(rsp => {
      console.log('report response ' + rsp);
      if ((rsp.status === 'success') && (rsp.data)) {
        // route to destination.
        this.router.navigate(['/ma/reportresults', rsp.data.groupId, rsp.data.reportId]);
      }
      else {
        this.errorMessage = rsp.message;
      }
    });
  }

}
