import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BaseResponse } from 'src/app/services/base.service';
import { Config, ConfigService } from 'src/app/services/config.service';
import { MeetupService, Ticket } from '../../services/meetup.service';

@Component({
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  public tickets?: Ticket[];
  public errorMessage?: string;
  public conflicts?: string[];

  private config: Config = new Config();

  constructor(
    configService: ConfigService,
    private meetupService: MeetupService) {
      configService.getConfig().then(cfg => {
        this.config = cfg;
      });
     }

  ngOnInit(): void {
    this.meetupService.calendar().subscribe(rsp => {
      if (rsp.status === 'success') {
        this.tickets = rsp.data?.tickets;
        this.conflicts = rsp.data?.analysis?.messages;
      }
      else {
        this.errorMessage = rsp.message;
      }
      console.log('calendar ' + JSON.stringify(rsp));
    },
    (err: HttpErrorResponse) => {
      if (err.status === 400) {
        const body = err.error as BaseResponse;
        console.error(body);
        if (body.message) {
          this.errorMessage = body.message;
        }
      }
      else if (err.status === 500) {
        console.error(err);
        this.errorMessage = `Unable to process your request due to a server error.  Please contact customer support at ${this.config.customerSupportContact}`;
      }
    });
  }

}
