

    <div class="card-body">
      <h4 class="card-title">Meetup Calendar</h4>
      <p class="card-text">Are you double booked? Many organizers prefer you choose
        an event in that case to allow others to attend. Open a summary of your upcoming meetup calendar and
        we'll look for times where you are double booked.
      </p>
    </div>
    <div class="card-footer">
        <button (click)="viewcalendar()" class="btn btn-primary">Open</button>
    </div>

