<div class="card-body">
  <h4 class="card-title">Crump Report</h4>
  <p class="card-text">Are you a group leader? Want to generate a report of who is coming and who is waitlisted
    for your event?</p>
  <p>
  <div class="form-group">
    <label for="group">Meetup Group</label>
    <select name="group" [(ngModel)]="selectedGroup" (change)="groupSelected()" class="form-control" id="group">
      <option *ngFor="let p of groupProfiles" [value]="p.group?.meetupId">{{p.group?.name}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="event">Event</label>
    <select name="event" [(ngModel)]="selectedEvent" class="form-control" id="event">
      <option *ngFor="let e of eventSummaries" [value]="e.meetupId">{{e.title}}</option>
    </select>
  </div>
  <div *ngIf="crumpErrorMessage" class="mt-3 alert alert-danger" role="alert">
    {{crumpErrorMessage}}
  </div>
</div>
<div class="card-footer">
  <button (click)="crump()" class="btn btn-primary">
    Generate DOCX
  </button>
</div>