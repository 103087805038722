import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BaseResponse } from './base.service';

export class Config {
  customerSupportContact?: string;
  redirectUri?: string;
  meetupKey?: string;
  recaptchaSiteKey?: string;
  recaptchaRequired?: boolean;
}

export class ConfigResponse extends BaseResponse {
  public config: Config = new Config();
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config?: Config;
  private promise?: Promise<Config>;


  constructor(private http: HttpClient) {
  }

  public getConfig(): Promise<Config> {
    if (this.config) {
      return Promise.resolve(this.config);
    }
    else if (this.promise) {
      return this.promise;
    }
    else {
      this.promise = new Promise<Config>((resolve, reject) => {
        const u = `${environment.baseurl}/config`;
        this.http.get<ConfigResponse>(u).subscribe({
          next: (rsp) => {
            if (rsp.status === 'success') {
              resolve(rsp.config);
            }
            else {
              reject(rsp.message);
            }
          }
        });
      });
      return this.promise;
    }
  }
}
