<div class="card-body">
  <h4 class="card-title">Membership Reports</h4>
  <p class="card-text">As a group organizer, lets take a look at the members of your group.</p>
  <p>
  <form>
    <div class="form-group">
      <label for="group">Meetup Group</label>
      <select name="group" [(ngModel)]="selectedGroup" class="form-control" id="group">
        <option *ngFor="let p of groupProfileValues" [value]="p.group?.id">{{p.group?.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="reportType">Report Type</label>
      <select name="reportType" [(ngModel)]="selectedReport" class="form-control" id="reportType">
        <option *ngFor="let rt of reportTypes" [value]="rt.id">{{rt.name}}</option>
      </select>
    </div>
  </form>
  <div *ngIf="errorMessage" class="mt-3 alert alert-danger" role="alert">
    {{errorMessage}}
  </div>
  <div *ngIf="infoMessage" class="mt-3 alert alert-info" role="alert">
    {{infoMessage}}
  </div>
</div>
<div class="card-footer">
  <button (click)="generateReport()" class="btn btn-primary">Generate</button>
</div>