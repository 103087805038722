import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from "@angular/forms";

export class FormFieldDefinition<T> {
  value?: T;
  key: string;
  label: string;
  required: boolean;
  readonly: boolean;
  order: number;
  controlType: string;
  type: string;
  placeholder: string;
  autocomplete: string;
  options: { key: string, value: T }[];

  isPassword = false;
  breakLine = false;
  pattern = '';
  maxLength?: string;

  constructor(options: {
    value?: T;
    key?: string;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: string;
    type?: string;
    placeholder?: string;
    autocomplete?: string;
    breakLine?: boolean;
    readonly?: boolean;
    pattern?: string;
    maxLength?: string;
    options?: { key: string, value: T }[];
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.autocomplete = options.autocomplete || '';
    this.required = !!options.required;
    this.readonly = !!options.readonly;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.breakLine = options.breakLine || false;
    this.pattern = options.pattern || '';
    this.maxLength = options.maxLength;
    this.options = options.options || [];

    this.isPassword = options.type === 'password';
  }
}

export class TextFormFieldDefinition extends FormFieldDefinition<string> {
  controlType = 'textbox';
}


export class DynamicFormComponent {

  protected toFormGroup(items: FormFieldDefinition<number|string>[]): FormGroup {
    const group: any = {};

    items.forEach(item => {
      const ctrl = new FormControl(item.value || '');
      const validators: ValidatorFn[] = [];
      if (item.required) {
        validators.push(Validators.required);
      }
      if (item.type === 'email') {
        validators.push(Validators.email);
      }
      if (item.pattern) {
        validators.push(Validators.pattern(item.pattern));
      }
      ctrl.setValidators(validators);
      group[item.key] = ctrl;
    });
    return new FormGroup(group);
  }
}


@Component({
  selector: 'app-form-element',
  templateUrl: './form-element.html'
})
export class DynamicFormElementComponent {
  @Input() item: FormFieldDefinition<number|string> = new TextFormFieldDefinition();
  @Input() form: FormGroup = new FormGroup({});
  @Input() ngf?: FormGroupDirective;
  @Input() elementClass = '';

  get isValid(): boolean | undefined {
    const rv = this.item && this.form?.controls[this.item?.key].valid;
    console.debug('validate element ' + this.item.key + ' is ' + rv);
    return rv;
  }


  showPass(fld: string): void {
    this.item.type = this.item.type === 'password' ? 'text' : 'password';
    // pw.type = pw.type === 'password' ? 'text' : 'password';
  }
}
