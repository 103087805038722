<div class="row">
<div *ngIf="errorMessage" class="mt-3 col-6 mx-auto alert alert-danger" role="alert">
  {{errorMessage}}
</div>
</div>
<div *ngIf="conflicts">
  <div *ngFor="let c of conflicts;" class="mt-3 alert alert-warning" role="alert">
    {{c}}
  </div>
</div>


<table class="table table-striped" *ngIf="tickets && tickets.length > 0">
  <thead>
    <tr>
      <th scope="col">Date/Time</th>
      <th scope="col">Status</th>
      <th scope="col">Group</th>
      <th scope="col">Name</th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{'table-warning': t.highlighted}" *ngFor="let t of tickets;">
      <td>{{t.event.dateTime | date}}</td>
      <td>{{t.status}}</td>
      <td><a href="https://meetup.com/{{t.event.group?.urlname}}">{{t.event.group?.name}}</a></td>
      <td><a href="{{t.event.eventUrl}}">{{t.event.title}}</a></td>
    </tr>
  </tbody>
</table>
<div *ngIf="tickets && tickets?.length === 0">
  No upcoming events found.
</div>