import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';
import { AttendeeAnalysis, AttendeeAnalysisItem, MeetupService } from 'src/app/services/meetup.service';

import { environment } from '../../../environments/environment';



@Component({
  templateUrl: './attendeeanalysisresults.component.html',
  styleUrls: ['./attendeeanalysisresults.component.scss']
})
export class AttendeeAnalysisResultsComponent implements OnInit {

  public errorMessage?: string;
  public data?: AttendeeAnalysis;
  public waitlist: AttendeeAnalysisItem[] = [];
  public going: AttendeeAnalysisItem[] = [];

  constructor(
    private configService: ConfigService,
    private authService: AuthenticationService,
    private meetupService: MeetupService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const groupId = this.route.snapshot.params.gid;
    const eventId = this.route.snapshot.params.eid;
    console.log('id in snapshot is ' + groupId + ' ' + eventId);
    this.meetupService.getAttendeeAnalysis(groupId, eventId).subscribe(rsp => {
      this.data = rsp.data;
      this.data?.items?.forEach(item => {
        item.cachedImageUrl = `${environment.baseurl}/mh/image?source=${item.thumbnailUrl}`;

        item.noshows = [];
        item.absences = [];
        item.lateNos = [];

        item.anomalies.forEach(a => {
          if (a.type === 'absent') {
            if (item.absences) {
              item.absences.push(a);
            }
          }
          else if (a.type === 'noshow') {
            if (item.noshows) {
              item.noshows.push(a);
            }
          }
          else if (a.type === 'lastminno') {
            if (item.lateNos) {
              item.lateNos.push(a);
            }
          }
        });
        if (item.rsvpResponse === 'waitlist') {
          this.waitlist.push(item);
        }
        else if (item.rsvpResponse === 'yes') {
          this.going.push(item);
        }
        else {
          console.warn('what up with ' + item.rsvpResponse);
        }
        this.waitlist.sort((a1, a2) => {
          return a1.rsvpUpdated > a2?.rsvpUpdated ? 1 : a2.rsvpUpdated > a1.rsvpUpdated ? -1 : 0
        });
        this.going.sort((a1, a2) => {
          return a1.rsvpUpdated > a2?.rsvpUpdated ? 1 : a2.rsvpUpdated > a1.rsvpUpdated ? -1 : 0
        });
      });
    });
  }

  content(item: any): string {
    return 'Hello';
  }
}
