import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  templateUrl: './malink.component.html',
  styleUrls: ['./malink.component.scss']
})
export class MaLinkComponent {

  public clientId?: string;
  public username?: string;
  public redirUrl?: string;

  constructor(
    private configService: ConfigService,
    private authService: AuthenticationService
    ) {

      configService.getConfig().then(cfg => {
      this.clientId = cfg.meetupKey;
      this.redirUrl = cfg.redirectUri;
      this.username = authService.authState.username;
     });
  }
}
