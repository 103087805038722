<div class="container py-5">
  <div class="row">
    <div class="col-md-6 mx-auto border-left border-right border-bottom p-0">
      <div class="card">
        <div class="card-header text-center">Meetup Assistant Signup</div>
        <div class="card-body">
          <form #signupForm="ngForm" (ngSubmit)="submit()" class="m-3" [formGroup]="form">
            <div class="row">
              <ng-template ngFor let-def [ngForOf]="formDef">
                <div class="col-6">
                  <app-form-element class="w-100" [item]="def" [form]="form" [ngf]="signupForm"></app-form-element>
                </div>
                <div *ngIf="def.breakLine" class="col"></div>
              </ng-template>
            </div>

       
            
            <div class="mt-4 row justify-content-center">
              <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Submit</button>
            </div>

            <div *ngIf="errorMessage" class="mt-3 alert alert-danger" role="alert">
              {{errorMessage}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>