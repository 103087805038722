import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';
import { EventsData, Event, MeetupService } from 'src/app/services/meetup.service';

@Component({
  templateUrl: './eventswithresults.component.html',
  styleUrls: ['./eventswithresults.component.scss']
})
export class EventsWithResultsComponent implements OnInit {

  public errorMessage?: string;
  public data?: EventsData;
  public events?: Event[];

  constructor(
    private configService: ConfigService,
    private meetupService: MeetupService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    this.meetupService.findEventsWith(id).subscribe(rsp => {
      if ((rsp.status === 'success') && (rsp.data)) {
        this.data = rsp.data;
        if (rsp.data?.events) {
          this.events = rsp.data?.events as Event[];
        }
      }
    });
  }
}
