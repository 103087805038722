import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HasAccessTokenGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {

  }

  /**
   * Returns true if the linked to meetup.  Examine token.
   */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    console.log('canActivate - is user linked to meetup?');

    const authState = this.authenticationService.getAuthData();
    if (!authState || !authState.token) {
      this.router.navigate(['/sec/login',
      ],
        { skipLocationChange: true }
      );
      return false;
    }
    const helper = new JwtHelperService();
    const decoded = helper.decodeToken(authState.token);
    console.log(decoded);
    if (!decoded.hasAccessToken) {
      this.router.navigate(['/ma/malink']);
      return false;
    }
    return true;
  }
}
