<nav class="navbar navbar-light navbar-fixed-top" style="background-color: #e3f2fd;">

  <div class="container-fluid">


    <span class="navbar-brand mb-0 h1">

      <a [routerLink]="['/ma/matools']">Meetup Assistant</a>
    </span>

    <form class="form-inline">
      <a *ngIf="!signedIn" [routerLink]="['/sec/signup']" class="btn btn-dark" type="button">Sign Up</a>
      <a *ngIf="!signedIn" [routerLink]="['/sec/login']" class="btn btn-dark ml-2" type="button">Sign In</a>
      <button *ngIf="signedIn" (click)="logout()" class="btn btn-dark" type="button">Sign Out</button>
      <a *ngIf="signedIn" class="nav-link avatar" [routerLink]="['/sec/profile']" href="#" role="button"
        aria-haspopup="true" aria-expanded="false">
        <i *ngIf="!hasAccessToken" class="fa fa-2x fa-user" aria-hidden="true"></i>
        <img class="profileimage rounded-circle" *ngIf="hasAccessToken" [src]="profileImageSource | secureImage | async"/>
      </a>
    </form>


    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
      </ul>

      <div class="d-flex">
      </div>
    </div>
  </div>
</nav>


<div class="container-fluid">
  <router-outlet></router-outlet>
</div>

<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-6">
          <button type="button" pButton (click)="onConfirm(message.data)" label="Yes" class="p-button-success"></button>
        </div>
        <div class="p-col-6">
          <button type="button" pButton (click)="onReject(message.data)" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>